import React from 'react';
import { Example } from '../styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// Component renders example render
import CodeBlock from "./CodeBlock"
import js_beautify from 'js-beautify';
const ExampleRender = ({ examples }) => {

  return (
    <div
      style={{
        marginTop: '0.5rem',
        padding: '0.3rem',
        display: examples && examples.length < 1 ? 'none' : 'block',
      }}
    >
      <b style={{ color: 'black' }}>Examples</b>
      {examples &&
        examples.length >= 1 &&
        examples.map((example) => {
          return (
            <div  style={{width: "100%"}}>
              <Example>
                <div style={{ display: 'block', float: 'right', position: 'relative' }}>
                  <CopyToClipboard text={`${example.raw || example.highlighted}`}>
                    <button>Copy</button>
                  </CopyToClipboard>
                </div>
                <div  style={{ display: "grid",  /* new */ }}>
                  <div style={{overflowX: "auto"}}>
                  <CodeBlock>
                    {js_beautify.js_beautify(example.raw)}
                  </CodeBlock>
                  </div>
                </div>
              </Example>
            </div>
          );
        })}
    </div>
  );
};

export default ExampleRender;
