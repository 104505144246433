import React from 'react';
import { Table, TableData, TableHead } from '../styles';

const ParamsRender = ({ params }) => {
  if (params) {
    return (
      <div style={{ padding: '0.3rem' }}>
        <b style={{ marginBottom: '1rem' }}>Parameters</b>
        <Table>
          <tr>
            <TableHead>Name</TableHead>
            <TableHead>Description</TableHead>
          </tr>
          {params.map((item) => {
            return (
              <>
                <tr>
                  <TableData>
                    <p style={{ padding: '0.5rem 0 0.5rem 0' }}>{item.name}</p>
                    <p>
                      <b style={{ padding: '0.5rem 0.5rem 0.5rem 0' }}>type</b>
                      <span
                        style={{
                          padding: '0.1rem',
                          backgroundColor: item.type ? '#ddd' : null,
                          borderRadius: '3px',
                        }}
                      >
                        {item.type ? `(${item.type.name})` : null}
                      </span>
                    </p>
                  </TableData>
                  <TableData
                    style={{ whiteSpace: 'pre-line' }}
                    dangerouslySetInnerHTML={{
                      __html: item.description ? item.description.childMarkdownRemark.html : null,
                    }}
                  />
                </tr>
              </>
            );
          })}
        </Table>
      </div>
    );
  }
  return null;
};

export default ParamsRender;
