import React from 'react';
import useCollapse from 'react-collapsed';
import Iconup from '../IconUp';
import ParamsRender from './ParamsRender';
import ExampleRender from './ExampleRender';
import TypeRender from './TypeRender';
import ReturnsRender from './ReturnsRender';
import { Wrapper, Title, CommentWrapper, Doc } from '../styles';

// Component renders a single node

const NodeRender = ({ doc }) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  const func_params =
    (doc.params &&
      doc.params.map((param) => {
        return param.name;
      })) ||
    [];
  return (
    <>
      <Wrapper isExpanded={isExpanded} {...getToggleProps()} href="#">
        <Title isExpanded={isExpanded}>
          <span>
            {doc.name}
            <b>
              (
              {func_params.length > 3
                ? func_params.slice(0, 3).toString() + ',...'
                : func_params.toString()}
            </b>
            )
          </span>
        </Title>
        <Iconup rotation={isExpanded ? 0 : -180} />
      </Wrapper>
      <div {...getCollapseProps()}>
        <CommentWrapper>
          <Doc>
            <div
              style={{ whiteSpace: 'pre-line', padding: '0.3rem' }}
              dangerouslySetInnerHTML={{
                __html: doc.description ? doc.description.childMarkdownRemark.html : null,
              }}
            />
            <TypeRender type={doc.type}/>
            <ParamsRender {...doc} />
          </Doc>
          <ReturnsRender return_values={doc.returns}/>
          <ExampleRender examples={doc.examples} />
        </CommentWrapper>
      </div>
    </>
  );
};

export default NodeRender;
