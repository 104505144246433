import styled from "styled-components";

export const Button = styled.div`
  position: fixed; 
  width: 40px;
  right: auto;
  left: calc(100vw - 80px);
  bottom: 40px;
  height: 20px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: #000;
  opacity: 0.6;
`;
