import * as React from "react";

function ThumsDownIcon({ color }) {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.6189 1.40496C15.2514 1.40496 16.7364 3.32496 16.7364 3.32496L21.2214 9.54246C22.2189 11.2675 20.7789 13.21 18.8964 13.21H13.8489C13.5789 13.21 13.3839 13.465 13.4514 13.72L14.3064 17.5375C14.7114 19.345 14.4639 20.1625 14.0589 20.7175C13.4139 21.61 12.1614 21.79 11.3964 20.995C11.0889 20.6725 10.2639 19.1725 9.9564 18.6475C8.7264 16.5475 7.7439 15.0775 6.8364 13.9525C6.2964 13.285 5.9964 12.445 5.9964 11.5825V5.16246C5.9964 3.08496 7.6839 1.39746 9.7614 1.39746H12.6264L12.6189 1.40496Z" fill={color} stroke="#FAFBF8" stroke-miterlimit="10"/>
<path d="M5.15639 14.5525H2.24639C1.77833 14.5525 1.3989 14.1731 1.3989 13.705L1.3989 2.73249C1.3989 2.26443 1.77833 1.88499 2.24639 1.88499H5.15639C5.62446 1.88499 6.00389 2.26443 6.00389 2.73249L6.00389 13.705C6.00389 14.1731 5.62446 14.5525 5.15639 14.5525Z" fill={color} stroke="#FAFBF8" stroke-miterlimit="10"/>
</svg>

  );
}


export default ThumsDownIcon;
