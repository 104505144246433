import React from 'react';
import styled from '@emotion/styled';

import NightImage from './images/sun.svg';
import DayImage from './images/moon.svg';
 

const StyledSwitch = styled('div')`
  display: flex;
  justify-content: space-around;
  width: 100%;
  ${'' /* padding: 0 20px; */}

  @media (max-width: 767px) {
    padding: 10px;
    justify-content:right;
  }

  .text_mode{padding-right:10px}

  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 37px;
    height: 22px; 
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(120, 120, 128, 0.16);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    box-shadow: 0 2.20161px 5.87097px rgba(0, 0, 0, 0.15), 0px 2.20161px 0.733871px rgba(0, 0, 0, 0.06);
    background: white;
    /* background: white url(${NightImage}); */
    background-repeat: no-repeat;
    background-position: center;
  }

  input:checked + .slider {
    background: linear-gradient(to right, #FFD100, #FFD100);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
    background: white;
    /* background: white url(${DayImage}); */
    background-repeat: no-repeat;
    background-position: center;
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%; 
  }
`;
 

const Button = styled.button`
color: ${({ theme }) => theme.colors.text};
background:none;
border:none;
cursor:pointer;
font-size:16px;
font-family:Nunito;
margin-left:5px;
`;

export const DarkModeSwitch = ({ isDarkThemeActive, toggleActiveTheme, theme }) => (
  <StyledSwitch>
    <img src={isDarkThemeActive ? NightImage : DayImage} alt="mood icon" />
    <Button theme={theme} onClick={toggleActiveTheme}>{isDarkThemeActive ? 'Light Mode' : 'Dark Mode'}</Button>
  </StyledSwitch>
);
