import React from 'react';

// Component renders

const ReturnsRender = ({ return_values }) => {
  if (!return_values) {
    return null;
  }
  return (
    <div
      style={{
        marginTop: '0.5rem',
        padding: '0.3rem',
      }}
    >
      <p style={{ color: 'black' }}>Returns </p>
      {return_values.map((return_value) => {
        return (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '0.2fr 1fr',
              color: 'black',
              marginTop: '1rem',
              marginBottom: '1rem',
              fontWeight: 'normal',
            }}
          >
            <b
              style={{
                color: 'black',
                fontWeight: 'normal',
                padding: '0.1rem',
                backgroundColor: '#ddd',
                borderRadius: '3px',
                textAlign: 'center',
                width: 'auto',
              }}
            >
              {return_value.type.name}
            </b>
            <p
              style={{ whiteSpace: 'pre-line', textAlign: 'center', width: 'auto' }}
              dangerouslySetInnerHTML={{
                __html: return_value.description
                  ? return_value.description.childMarkdownRemark.html
                  : null,
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ReturnsRender;
