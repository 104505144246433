import React from 'react';
import { connectStateResults } from 'react-instantsearch-dom';
import styled from '@emotion/styled';

const ResultFound = styled.div`
font-weight:700;
color: ${props => props.theme.colors.text}
`

const Results = (search) => {
  const { searching, searchResults, allSearchResults, setTotalPages } = search
  console.log('allSearchResults',allSearchResults)
  if (allSearchResults && allSearchResults.nbPages <= 1) {
    setTotalPages(0)
  } else {
    if (allSearchResults && allSearchResults.nbPages < 5) {
      setTotalPages(allSearchResults.nbPages)
    } else {
      setTotalPages(5)
    }
  }

  return <ResultFound>{(searching && `Searching...`) || (searchResults && searchResults.nbHits !== 0 && `${allSearchResults.nbHits} results found`)}</ResultFound>
};
export default connectStateResults(Results);