import React from 'react';

// Component renders a single node

const TypeRender = ({ type }) => {
  if (!type) {
    return null;
  }
  return (
    <div
      style={{
        marginTop: '0.5rem',
        padding: '0.3rem',
      }}
    >
      <b>Type </b>
      {type.name}
    </div>
  );
};

export default TypeRender;
