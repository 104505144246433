import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';

import { Layout, Link } from '$components';
import NextPrevious from '../components/NextPrevious';
import config from '../../config';
import {StyledHeading, StyledMainWrapper, BreadCums, TitleWrapper } from '../components/styles/Docs';

import favicon16 from '../images/favicon16.png';
import ArticleHelpulComponent from '../components/articleHelpFul';
import SearchListComponent from '../components/searchListing/index';

import MyToc from "gatsby-plugin-md-menus/toc"
import Navigation from "gatsby-plugin-md-menus/navigation";

const forcedNavOrder = config.sidebar.forcedNavOrder;

export default class MDXRuntimeTest extends Component {
  render() {
    const { data } = this.props;

    if (!data) {
      return this.props.children;
    }
    const {
      allMdx,
      mdx
    } = data;

    const navItems = allMdx.edges
      .filter(({ node }) => node.fields !== null)
      .map(({ node }) => node.fields.slug)
      .filter((slug) => slug !== '/')
      .sort()
      .reduce(
        (acc, cur) => {
          if (forcedNavOrder.find((url) => url === cur)) {
            return { ...acc, [cur]: [cur] };
          }

          let prefix = cur.split('/')[1];

          if (config.gatsby && config.gatsby.trailingSlash) {
            prefix = prefix + '/';
          }

          if (prefix && forcedNavOrder.find((url) => url === `/${prefix}`)) {
            return { ...acc, [`/${prefix}`]: [...acc[`/${prefix}`], cur] };
          } else {
            return { ...acc, items: [...acc.items, cur] };
          }
        },
        { items: [] }
      );

    const nav = forcedNavOrder
      .reduce((acc, cur) => {
        return acc.concat(navItems[cur]);
      }, [])
      .concat(navItems.items)
      .map((slug) => {

        if (slug) {
          const { node } = allMdx.edges.find(
            ({ node }) => node.fields && node.fields.slug === slug
          );

          return { title: node.fields.title, url: node.fields.slug, parent: null };
        }
        return false;
      });


    const parentTitle = nav.filter((item) => {
      const parentArr = mdx.fields.slug.split('/')
      const parentUrl = parentArr && parentArr.length > 2 ? '/' + parentArr[1] : null
      return parentUrl === item.url ? item.title : null
    })


    // meta tags
    const metaTitle = mdx.frontmatter.metaTitle;

    const metaDescription = mdx.frontmatter.metaDescription;
    let searchIndices = [];
    searchIndices.push({
      name: `${config.header.search.indexName}`,
      title: `Results`,
      hitComp: `PageHit`,
    });
    if (mdx.fields.slug === '/search-result') {
      return (
        <Layout {...this.props}>
          <Helmet>
            {metaTitle ? <title>{metaTitle}</title> : null}
            {metaTitle ? <meta name="title" content={metaTitle} /> : null}
            {metaDescription ? <meta name="description" content={metaDescription} /> : null}
            {metaTitle ? <meta property="og:title" content={metaTitle} /> : null}
            {metaDescription ? <meta property="og:description" content={metaDescription} /> : null}
            {metaTitle ? <meta property="twitter:title" content={metaTitle} /> : null}
            {metaDescription ? (
              <meta property="twitter:description" content={metaDescription} />
            ) : null}
            <link
              rel="icon"
              href={favicon16}
              type="image/png"
              sizes="16x16"
            />
          </Helmet>
          <SearchListComponent indices={searchIndices} collapse={true} hitsAsGrid={true} theme={{}} />
        </Layout>
      )
    }
    return (
      <Layout {...this.props}>
        <Helmet>
          {metaTitle ? <title>{metaTitle}</title> : null}
          {metaTitle ? <meta name="title" content={metaTitle} /> : null}
          {metaDescription ? <meta name="description" content={metaDescription} /> : null}
          {metaTitle ? <meta property="og:title" content={metaTitle} /> : null}
          {metaDescription ? <meta property="og:description" content={metaDescription} /> : null}
          {metaTitle ? <meta property="twitter:title" content={metaTitle} /> : null}
          {metaDescription ? (
            <meta property="twitter:description" content={metaDescription} />
          ) : null}

          <link
            rel="icon"
            href={favicon16}
            type="image/png"
            sizes="16x16"
          />
        </Helmet>
        <BreadCums className='breadcums'>
            <ul>
              {parentTitle[0] ? <li><Link to={parentTitle[0].url}>{parentTitle[0].title}</Link></li> : null}
              <li>{mdx.fields.title}</li>
            </ul>
          </BreadCums>
          <TitleWrapper className={'titleWrapper'}>
            <StyledHeading>{mdx.fields.title}</StyledHeading>
          </TitleWrapper>
        <StyledMainWrapper>
          <MDXRenderer>{mdx.body}</MDXRenderer>
          <MyToc navItems={navItems} mdx={mdx} allMdx={allMdx} />
        </StyledMainWrapper>
        {(mdx.fields.slug).search('/misc') === -1?<ArticleHelpulComponent />:null}
        <div className={'addPaddTopBottom'}>
          <Navigation mdx={mdx} >
            <NextPrevious />
          </Navigation>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    site {
      siteMetadata {
        title
        docsLocation
      }
    }
    mdx(fields: { id: { eq: $id } }) {
      fields {
        id
        title
        slug
      }
      body
      tableOfContents
      parent {
        ... on File {
          relativePath
        }
      }
      frontmatter {
        metaTitle
        metaDescription
      }
    }
    allMdx {
      edges {
        node {
          fields {
            slug
            title
          }
        }
      }
    }
  }
`;
