import React from 'react';
import styled from '@emotion/styled';
import ThumsUpIcon from './icons/thumsUp';
import ThumsDownIcon from './icons/thumsDown';

const ArticleBlock = styled('div')`
    width: 100%;
    max-width:750px;
    padding: 20px 35px;
    background-color: ${props => props.theme.helpSection.bgCol};
    border-radius:12px;
    border: 1px solid ${props => props.theme.helpSection.outerBorder};
    margin-top:40px;
`

const YesButton = styled('button')`
    background-color: #FFFFFF;
    color: ${props => props.theme.colors.greenCol};
    padding: 10px 127px;
    font-size:16px;
    font-weight:700;
    border-radius:8px;
    border: 2px solid ${props => props.theme.colors.greenCol};
    margin : 25px 30px 10px 0;
    cursor:pointer;
    svg {
        path {
            fill:${props => props.theme.colors.greenCol};
        }
    }
    &:hover {
        background-color: ${props => props.theme.helpSection.hoverCol};
        border-color: ${props => props.theme.helpSection.hoverCol};
        color: #ffffff;
        svg {
            path {
                fill:#ffffff;
                stroke:${props => props.theme.helpSection.hoverCol};
            }
        }
    }
    @media (max-width: 1024px) {
        padding:10px 0;
        width:100%;
        margin:15px 0 5px;
    }
`
const NoButton = styled('button')`
    background-color: #FFFFFF;
    color: ${props => props.theme.colors.greenCol};
    padding: 10px 127px;
    font-size:16px;
    font-weight:700;
    border-radius:8px;
    border: 2px solid ${props => props.theme.colors.greenCol};
    margin: 25px 0 10px 0;
    cursor:pointer;
    svg {
        path {
            fill:${props => props.theme.colors.greenCol};
        }
    }
    &:hover {
        background-color: ${props => props.theme.helpSection.hoverCol};
        border-color: ${props => props.theme.helpSection.hoverCol};
        color: #ffffff;
        svg {
            path {
                fill:#ffffff;
                stroke:${props => props.theme.helpSection.hoverCol};
            }
        }
    }
    @media (max-width: 1024px) {
        padding:10px 0;
        width:100%;
        margin:5px 0;
    }
`
const FeedbackHead = styled('h2')`
font-size: 20px;
font-weight: 800;
color: ${props => props.theme.colors.text};
`

const FeedbackText = styled('span')`
vertical-align:top;
padding:2px 0 0 5px;
display:inline-block;
`

const ThumbUp = styled(ThumsUpIcon)`
`

const ThumbDown = styled(ThumsDownIcon)`
  
`


const ArticleHelpulComponent = () => {
    return (
        <ArticleBlock>
            <FeedbackHead>
                 Was this article helpful?
            </FeedbackHead>
            <YesButton id='yes_button'>
                <ThumbUp color={'#7BC12E'} />
                <FeedbackText>Yes</FeedbackText>
            </YesButton>
            <NoButton id='no_button'>
                <ThumbDown color={'#7BC12E'} /> 
                <FeedbackText>No</FeedbackText>
            </NoButton>
        </ArticleBlock>
    );
};

export default ArticleHelpulComponent;
