import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import Link from '../link';
import useStackOverflowHook from '../../hooks/useStackOverflowHook'
import { ThemeContext } from '@emotion/react';
const ListBlock = styled('div')`
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  & a {
    color: ${({ theme }) => theme.leftSideBar.troubleshootingLinks};
    &:hover {
      color: #F6911E;
      text-decoration:underline;
    }
  }

`;
const DataItems = (props) => {
  if (Array.isArray(props.items)) {
    return <ul>
      {props.items.map((item, index) => {
        return <li key={index} ><Link to={item.link}>{item.title}</Link></li>
      })}
    </ul>
  }
  return <></>
}
const TroubleshootingList = () => {
  const [myData, setMyData] = useState([])
  const url = 'https://api.stackexchange.com/2.3/questions?order=desc&sort=activity&tagged=edgeEngine&site=stackoverflow&key=xzqzQxmVWnCXZhLvCiP1Tg(('
  const stackOverflowData = useStackOverflowHook(url);
  const themeContext = useContext(ThemeContext)
  useEffect(() => {
    setMyData(() => {
      return stackOverflowData.items;
    })
  }, [stackOverflowData])
  return (
    <div className='trobbuleshoot_listing'>
      <ListBlock theme={{ ...themeContext }}>
        <DataItems items={myData} ></DataItems>
      </ListBlock>
    </div>
  )
  /*
  return (
      <div>
          <ListBlock>
              <DataItems items={myData} ></DataItems>
          </ListBlock>
          < TSBase >
              <TSRow>
                  <TDPrev>&#8592; Last 10 Questions NOT IMPLEMENTED </TDPrev>
                  <TDNext>Next 10 Questions NOT IMPLEMENTED &#8594;</TDNext>
              </TSRow>
          </TSBase >
      </div>
  )
  */
}
export default TroubleshootingList;
