import React, { useState, useEffect, createRef } from 'react';
import {
  InstantSearch,
  Index,
  Configure,
  SearchBox
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import config from '../../../config.js';

import styled from '@emotion/styled';


import Pagination from './Pagination.js';
import CustomHits from "./Hits"
import Results from './Results.js';

const HitsWrapperList = styled.div`
 
  @media only screen and (max-width: 991px) {
    width: 400px;
    max-width: 400px;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    max-width: 500px;
  }
   
 
  }
 
`;

const TitleWrapper = styled.div`
border-bottom: 1px solid ${props => props.theme.colors.greenCol};
padding-bottom:3px;
margin-bottom: 5px;
font-size: 32px;
color: ${props => props.theme.colors.text}
`

const TitleBold = styled.strong`
  font-weight:700
`

const Root = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1em;
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

const HideSearchBox = styled.div`
  display:none
`;
 




const searchClient = algoliasearch(
  config.header.search.algoliaAppId,
  config.header.search.algoliaSearchKey
);



export default function SearchListComponent({ indices, hitsAsGrid }) {
 

  const ref = createRef();
  const isBrowser = typeof window !== "undefined"
  let searchParams = ''
  if(isBrowser) {
    const url = window.location.search   
    if(url) {
      searchParams = (((url.split('=').reverse())[0]).trim()).replaceAll('%20',' ');
    }    
  }
  
  const [query, setQuery] = useState(searchParams);

  useEffect(()=>{
    setQuery(searchParams)
  },[searchParams])

  const [totalPages, setTotalPages] = useState(null);
 
  return (
 
    <InstantSearch
      searchClient={searchClient}
      indexName={indices[0] ? indices[0].name : ''}
      root={{ Root, props: { ref } }}
    >
      <HideSearchBox><SearchBox defaultRefinement={query} /></HideSearchBox>
      <TitleWrapper>
        <p>Search Result for <TitleBold>"{searchParams}"</TitleBold></p>
      </TitleWrapper>
      <HitsWrapperList
        show={query && query.length > 0}
        asGrid={hitsAsGrid}
        ref={ref}
      >
        {indices.map(({ name }) => {
          return (
            <Index key={name} indexName={name}>
              <Results setTotalPages={setTotalPages} />
              <CustomHits />
            </Index>
          );
        })}
        {totalPages > 0 ? <Pagination
          showPrevious={true}
          showNext={true}
          totalPages={totalPages}
        /> : null}
      </HitsWrapperList>
      <Configure hitsPerPage={10} />
    </InstantSearch>
  
  );
}
