import React from 'react';
import styled from '@emotion/styled';
import { MDXProvider } from '@mdx-js/react';

import ThemeProvider from './theme/themeProvider';
import mdxComponents from './mdxComponents';
import Sidebar from './sidebar';
import RightSidebar from './rightSidebar';
import Footer from "../components/footer";
import config from '../../config.js';
import ScrollToTop from './scrollTotop';
import PackagesJson from '../../package.json';
 
const Wrapper = styled('div')`
  display: flex;
  min-height: calc(100vh - 140px);
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.background};
  .firstLevel > ul{border-top:${({ theme }) => theme.section.navBorder} 1px solid}
  .firstLevel > ul > li{border-bottom:${({ theme }) => theme.section.navBorder} 1px solid}
  .firstLevel > ul > li > a{font-weight:600; font-size:16px; padding:8px 8px 8px 50px;display:block; position:relative;}
  .firstLevel > ul > li > a > button{ position:absolute; left:16px; top:15px; background:none; border:none;  margin-right:10px}
  
  .firstLevel > ul > li > a > button svg{width:16px; height:16px}
  .firstLevel > ul > li > a > button svg path{fill:${({ theme }) => theme.leftSideBar.iconNormal};}
  .firstLevel > ul > li > a:hover > button svg path{fill:${({ theme }) => theme.leftSideBar.iconHover};}
  .sideBarUL > li > ul > li.selected{
    background-color: ${({ theme }) => theme.colors.lightGreen};
    border-top: ${({ theme }) => theme.section.navActive} 1px solid;
    border-bottom: ${({ theme }) => theme.section.navActive} 1px solid;
  }
  .firstLevel > ul > li > ul > li > a{display:block}
  .firstLevel > ul > li.active > a,   .firstLevel > ul > li > ul > li.active{
    background-color: ${({ theme }) => theme.leftSideBar.menuActive}; display:block
  }
  .firstLevel > ul > li > a:hover,   .firstLevel > ul > li > ul > li:hover{
    background-color: ${({ theme }) => theme.leftSideBar.menuHover};  
  }
  .firstLevel > ul > li.active > a, .firstLevel > ul > li > ul > li.active > a{
    color: #001933;
  }
  .firstLevel > ul > li > ul{padding-left:50px}
  .firstLevel > ul > li > ul > li{list-style-type:none; border-bottom: 1px solid ${({ theme }) => theme.section.navChildBorder}; padding:8px}



  .sideBarUL > li > ul > li > a {
    color: ${({ theme }) => theme.section.navParentCol};
  }
  .sideBarUL > li > ul > li > ul > li > a {
    color: ${({ theme }) => theme.section.navParentCol};
  }

  @media only screen and (max-width: 767px) {
    display: block;
  }
`;

const Content = styled('main')`
  display: flex;
  flex-grow: 1;
  margin: 0px 50px;
  padding-top: 20px;
  background: ${({ theme }) => theme.colors.background};

  table tr {
    background: ${({ theme }) => theme.colors.background};
  }

  @media only screen and (max-width: 1023px) {
    padding-left: 0;
    margin: 0 10px;
    padding-top: 3rem;
  }
`;

const MaxWidth = styled('div')`
  @media only screen and (max-width: 50rem) {
    width: 100%;
    position: relative;
  }
`;

const LeftSideBarWidth = styled('div')`
  width: 298px;
  min-width: 298px;
  max-width: 298px;
  background-color: ${({ theme }) => theme.colors.leftSection};
  border-right: ${({ theme }) => theme.colors.darkGreen} 1px solid;
  position:relative;

  .firstLevel > ul{border-top:${({ theme }) => theme.section.navBorder} 1px solid}
  .firstLevel > ul > li{border-bottom:${({ theme }) => theme.section.navBorder} 1px solid}
  .firstLevel > ul > li > a{font-weight:600; font-size:16px; padding:8px 8px 8px 50px;display:block; position:relative;}
  .firstLevel > ul > li > a > button{ position:absolute; height:100%; left:0; top:0; padding:12px 0 6px 16px; background:none; border:none;   width:100%; text-align:left; cursor:pointer}
  .firstLevel > ul > li > a > button svg{width:16px; height:16px}
  .firstLevel > ul > li > a > button svg path{fill:${({ theme }) => theme.leftSideBar.iconNormal};}
  .firstLevel > ul > li > a:hover > button svg path{fill:${({ theme }) => theme.leftSideBar.iconHover};}
  .firstLevel > ul > li > ul > li > a > button{display:none}
  .sideBarUL > li > ul > li.selected{
    background-color: ${({ theme }) => theme.colors.lightGreen};
    border-top: ${({ theme }) => theme.section.navActive} 1px solid;
    border-bottom: ${({ theme }) => theme.section.navActive} 1px solid;
  }
  .firstLevel > ul > li > ul > li > a{display:block}
  .firstLevel > ul > li.active > a,   .firstLevel > ul > li > ul > li.active{
    background-color: ${({ theme }) => theme.leftSideBar.menuActive}; display:block
  }
  .firstLevel > ul > li > a:hover,   .firstLevel > ul > li > ul > li:hover{
    background-color: ${({ theme }) => theme.leftSideBar.menuHover};  
  }
  .firstLevel > ul > li.active > a, .firstLevel > ul > li > ul > li.active > a{
    color: #001933;
  }
  .firstLevel > ul > li > ul{padding-left:50px}
  .firstLevel > ul > li > ul > li{list-style-type:none; border-bottom: 1px solid ${({ theme }) => theme.section.navChildBorder}; padding:8px}



  .sideBarUL > li > ul > li > a {
    color: ${({ theme }) => theme.section.navParentCol};
  }
  .sideBarUL > li > ul > li > ul > li > a {
    color: ${({ theme }) => theme.section.navParentCol};
  }


  @media (max-width: 1024px) {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
  }

  @media (max-width: 900px) {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
  }
`;

const RightSideBarWidth = styled('div')`
  width: 440px;
`;

const SideBarTitle = styled('div')` 
color: ${({ theme }) => theme.leftSideBar.catTitle};;
padding: 34px 0 7px 49px
`;


const DeveloperVersion = styled('div')` 
color: ${({ theme }) => theme.leftSideBar.catTitle};;
position:absolute;
left:0;
bottom:15px;
font-size:15px;
font-weight:700;
width:296px;
text-align:center
`;

 

const Layout = ({ children, location }, props) => {
  return (<ThemeProvider location={location}>
    <MDXProvider components={mdxComponents}>
      <Wrapper>
        <LeftSideBarWidth className={'hiddenMobile'}>
        <SideBarTitle>
          Categories
        </SideBarTitle>
          <Sidebar location={location} />
          <DeveloperVersion>developer documentation v{PackagesJson.version}</DeveloperVersion>
        </LeftSideBarWidth>
        {config.sidebar.title ? (
          <div
            className={'sidebarTitle sideBarShow'}
            dangerouslySetInnerHTML={{ __html: config.sidebar.title }}
          />
        ) : null}
        <Content>
          <MaxWidth>{children}</MaxWidth>
        </Content>
        <RightSideBarWidth className={'hiddenMobile'}>
          <RightSidebar location={location} />
        </RightSideBarWidth>
      </Wrapper>
      <ScrollToTop />
      <Footer />
    </MDXProvider>
  </ThemeProvider>)
};

export default Layout;
