import styled from '@emotion/styled';

export const StyledNextPrevious = styled('div')`
  margin: 0px;
  padding: 0px;
  width: auto;
  display: grid;
  grid-template-rows: auto;
  column-gap: 24px;
  grid-template-columns: calc(50% - 8px) calc(50% - 8px);

  .previousBtn {
    cursor: pointer;
    -moz-box-align: center;
    -moz-box-direction: normal;
    -moz-box-orient: horizontal;
    margin: 0px;
    padding: 0px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    place-self: stretch;
    border-radius: 3px;
    transition: border 200ms ease 0s; 
    text-decoration: none;
    text-align:left;

    background-color: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    .smallContent{
      border-bottom:${props => props.theme.pagination.pagingCol} 1px solid;
      color:${props => props.theme.pagination.pagingCol};
    }
  }

  .nextBtn {
    cursor: pointer;
    -moz-box-align: center;
    -moz-box-direction: normal;
    -moz-box-orient: horizontal;
    margin: 0px;
    padding: 0px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    place-self: stretch;
    border-radius: 3px;
    text-align:right;
 
    transition: border 200ms ease 0s; 
    text-decoration: none;

    background-color: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};

    .smallContent{
      border-bottom:${props => props.theme.pagination.pagingCol} 1px solid;
      color:${props => props.theme.pagination.pagingCol};
    }
  }

  .nextBtn:hover,
  .previousBtn:hover {
    text-decoration: none; 
   
    .smallContent{
      color: ${props => props.theme.pagination.pagingHoverCol};
      border-bottom:${props => props.theme.pagination.pagingCol} 2px solid;
      span {
        font-weight:700;
      }
    }
  }

  .nextBtn:hover .rightArrow,
  .previousBtn:hover .leftArrow {
    color: ${props => props.theme.pagination.pagingHoverCol};
    .smallContent{
      color: ${props => props.theme.pagination.pagingHoverCol};
      border-bottom:${props => props.theme.pagination.pagingHoverCol} 2px solid;
      span {
        font-weight:700;
      }
    }
  }

  .leftArrow {
    display: flex;
    margin: 0px;
    color: rgb(157, 170, 182);
    flex: 0 0 auto;
    font-size: 24px;
    transition: color 200ms ease 0s;
    padding: 16px;
    padding-right: 16px;
  }

  .rightArrow {
    display: flex;
    flex: 0 0 auto;
    font-size: 24px;
    transition: color 200ms ease 0s;
    padding: 16px;
    padding-left: 16px;
    margin: 0px;
    color: rgb(157, 170, 182);
  }

  .nextPreviousTitle {
    display: block;
    min-height:42px;
    margin: 0px;
    padding: 0px;
    transition: color 200ms ease 0s;
  }

  .nextPreviousTitle span {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
  }

  @media (max-width: 767px) {
   
    padding: 0;

    .previousBtn {
      margin-bottom: 20px;
      .leftArrow {
        padding:0
      }
      .rightArrow {
        padding:0
      }
    }

    .nextBtn {
      margin-bottom: 20px;
      .rightArrow {
        padding:0
      }
      .nextRightWrapper {
        padding:0
      }
    }
  }
`;
