import * as React from "react";

function ArrowLeft() {

  return (
    <svg width="27" height="16" viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.9231 9C26.4754 9 26.9231 8.55228 26.9231 8C26.9231 7.44772 26.4754 7 25.9231 7L25.9231 9ZM0.29291 7.29289C-0.0976143 7.68342 -0.0976143 8.31658 0.29291 8.7071L6.65687 15.0711C7.04739 15.4616 7.68056 15.4616 8.07108 15.0711C8.46161 14.6805 8.46161 14.0474 8.07108 13.6569L2.41423 8L8.07109 2.34314C8.46161 1.95262 8.46161 1.31946 8.07109 0.928931C7.68056 0.538406 7.0474 0.538406 6.65687 0.928931L0.29291 7.29289ZM25.9231 7L1.00002 7L1.00002 9L25.9231 9L25.9231 7Z" fill="#A3CD39"/>
</svg>
  );
}


export default ArrowLeft;
