import * as React from "react";

function ThumsUpIcon({ color }) {

  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.559 20.5426C15.1915 20.5426 16.6765 18.6226 16.6765 18.6226L21.1615 12.3976C22.159 10.6726 20.719 8.73006 18.8365 8.73006H13.789C13.519 8.73006 13.324 8.47506 13.3915 8.22006L14.2465 4.40256C14.6515 2.59506 14.404 1.77756 13.999 1.22256C13.354 0.330063 12.1015 0.150063 11.3365 0.945063C11.029 1.26756 10.204 2.76756 9.89649 3.29256C8.66649 5.39256 7.68399 6.86256 6.77649 7.98756C6.23649 8.65506 5.93649 9.49506 5.93649 10.3576V16.7776C5.93649 18.8551 7.62399 20.5426 9.70149 20.5426H12.5665H12.559Z" fill={color} stroke="#FAFBF8" strokeMiterlimit="10"/>
    <path d="M5.09649 7.38757H2.18649C1.71843 7.38757 1.33899 7.76701 1.33899 8.23507V19.2076C1.33899 19.6756 1.71843 20.0551 2.18649 20.0551H5.09649C5.56455 20.0551 5.94399 19.6756 5.94399 19.2076V8.23507C5.94399 7.76701 5.56455 7.38757 5.09649 7.38757Z" fill={color} stroke="#FAFBF8" strokeMiterlimit="10"/>
    </svg>
  );
}


export default ThumsUpIcon;
