/*  
* This HOC component implements rendering of individual Hit .
* an individual hit is a single search result. In here we also 
* check to see if the hit has a title. If it does we render the
* search result otherwise we return null. 
*/
import React from "react"
import { connectHits } from 'react-instantsearch-dom';
import { Highlight, Snippet, } from 'react-instantsearch-dom';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

const SearchListing = styled.div`
 padding:20px 0;
 border-bottom: rgba(67, 93, 88, 0.25) 1px solid;
 color: ${props => props.theme.colors.text};
  & a {
    color: ${props => props.theme.colors.text}
  }
`;

const SearchLinks = styled.div`
 font-size:20px;
 font-weight:700;
 color: ${props => props.theme.colors.text};
 text-decoration:underline;
 &:hover{
  text-decoration:none;
  color: ${props => props.theme.colors.link} !important;
 }
`;


const Hits = ({ hits, onClick, theme }) => {
  return (
    <>
      {hits.map((hit, index) => {
        const { slug, title } = hit
        if (title) {
          return (
            <SearchListing key={index} theme={theme}>
              <Link to={slug} onClick={onClick}>
                <SearchLinks theme={theme}>
                  <Highlight attribute="title" hit={hit} tagName="mark" />
                </SearchLinks>
                <Snippet attribute="excerpt" hit={hit} tagName="mark" />
              </Link>
              
            </SearchListing>
          )
        }
        return null
      })}
    </>
  )
};
export default connectHits(Hits);