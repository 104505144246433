import React, { useState, useEffect } from "react";
import { ArrowUpCircle } from "@styled-icons/feather/ArrowUpCircle";

import { Button } from "./styles";

const isBrowser = () => typeof window !== "undefined";

function ScrollToTop() {
  const [visible, setVisible] = useState(false);
  const [movePosUp, setMovePosUp] = useState(false);
  const [width, setWidth] = useState(isBrowser() && window.innerWidth);

  const breakpoint = 768;

  useEffect(() => {
    if(typeof window !== "undefined") {
      window.addEventListener("resize", () => setWidth(window.innerWidth));
    }
  }, []);

  const toggleVisible = () => {
    const scrolled = isBrowser() && document.documentElement.scrollTop;

    // User scrolls past page title
    if(scrolled > 300) {
      setVisible(true);

      // Check viewport for mobile view
      if(width <= breakpoint) {
        // User reaches the page bottom
        if(typeof window !== "undefined") {
          if((scrolled + window.innerHeight) === document.documentElement.scrollHeight) {
            setMovePosUp(true);
          } else {
            setMovePosUp(false);
          }
        }
      } else {
        setMovePosUp(false);
      }
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollAction = () => {
    if(typeof window !== "undefined") {
      window.scrollTo({
        top: 0,
        behaviour: "smooth",
      });
    }
  };

  if(typeof window !== "undefined") {
    window.addEventListener("scroll", toggleVisible);
  }

  return (
    <Button>
      <ArrowUpCircle
        style={ {
          position: "relative",
          bottom: movePosUp ? "113px" : "60px",
          display: visible ? "inline" : "none",
        } }
        onClick={ scrollAction }
      />
    </Button>
  );
}

export default ScrollToTop;
