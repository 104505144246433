/* This is a HOC component that utilizes connectPagination component.
*  all props are optional with exception of total pages 
*/
import React from 'react';
import styled from '@emotion/styled';
import { connectPagination } from 'react-instantsearch-dom';
import ArrowLeft from '../icons/arrowLeft';
import ArrowRight from '../icons/arrowRight';

const PaginationStyle = styled.ul`
display:flex;
padding-top: 14px;
justify-content: space-between;
padding-bottom:20px
`
const PaginationItem = styled.li`
list-style-type : none;

 
&.prev_btn a{
    color : ${props => props.theme.colors.darkGreen};
    &:hover{
        color : ${props => props.theme.pagination.pagingHoverCol};
        svg {
            path {
                fill : ${props => props.theme.pagination.pagingHoverCol};
            }
        }
    }
}
 
&.next_btn a{
    color : ${props => props.theme.colors.darkGreen};
    &:hover{
        color : ${props => props.theme.pagination.pagingHoverCol};
        svg {
            path {
                fill : ${props => props.theme.pagination.pagingHoverCol};
            }
        }
    }
}
&.page_number a{
    margin: 0 10px;
    color : ${props => props.theme.colors.darkGreen};
    &:hover{
        color : ${props => props.theme.pagination.pagingHoverCol};
       
    }
}
& svg {
    margin:3px 7px 0 7px;
    display:inline-block;
    vertical-align:top
}
`

const PaginationLink = styled.a`

    font-weight:700;
    font-size : 15px;

    svg {
        path {
            fill : ${props => props.theme.colors.darkGreen};
        }
    }
   
`

const RightArrow = styled(ArrowRight)`
display:inline-block;
`
const LeftArrow = styled(ArrowLeft)`
`

const Pagination = ({ currentRefinement, nbPages, refine, createURL, totalPages, showFirst, showLast, showNext, showPrevious }) => {
    const nextPage = currentRefinement + 1 > totalPages ? totalPages : currentRefinement + 1
    const prevPage = currentRefinement - 1 < 1 ? 1 : currentRefinement - 1
    if(currentRefinement === 1) {
        showPrevious = false
    }
    if(currentRefinement === totalPages) {
        showNext = false
    }
    return (
        <PaginationStyle >

            <PaginationItem style={{ visibility: showPrevious ? "visible" : "hidden" }} className="prev_btn">
                
                <PaginationLink href={createURL(prevPage)} onClick={event => {
                    event.preventDefault();
                    refine(prevPage);
                }}>
                    <LeftArrow />
                    previous
                </PaginationLink>
            </PaginationItem>


            <PaginationItem className="page_number">
                {new Array(totalPages).fill(null).map((_, index) => {
                    const page = index + 1;
                    const style = {
                        fontWeight: currentRefinement === page ? 'bold' : '',
                        opacity: currentRefinement === page ? "1" : "0.5"
                    };

                    return (

                        <PaginationLink key={index} href={createURL(page)}
                            style={style}
                            onClick={event => {
                                event.preventDefault();
                                refine(page);
                            }}>
                            {page}
                        </PaginationLink>

                    );
                })}

            </PaginationItem>

            <PaginationItem style={{ visibility: showNext ? "visible" : "hidden" }} className='next_btn'>
                <PaginationLink href={createURL(nextPage)} onClick={event => {
                    event.preventDefault();
                    refine(nextPage);
                }}>
                    next
                    <RightArrow />
                </PaginationLink>
                
            </PaginationItem>



        </PaginationStyle>
    )
}
    ;
export default connectPagination(Pagination);