import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import NodeRender from './renders/NodeRender';
import NodeChildrenRender from './renders/NodeChildrenRender';
import References from './references';
// JSRender
const JsRenderer = ({ jsrenderer, files }) => {
  const data = useStaticQuery(graphql`
    query serverlessJsDocQuery {
      allFile {
        edges {
          node {
            extension
            dir
            modifiedTime
            relativePath
            sourceInstanceName
            childrenDocumentationJs {
              id
              name
              description {
                children {
                  id
                }
                childMarkdownRemark {
                  html
                  htmlAst
                }
                childrenMdx {
                  id
                  headings {
                    value
                    depth
                  }
                }
              }
              returns {
                id
                copyright
                name
                params {
                  id
                }
              }
              params {
                name
                type {
                  name
                }
                description {
                  childMarkdownRemark {
                    html
                  }
                }
              }
              examples {
                highlighted
                raw
              }
              type {
                type
                name
                expression
                result
              }
              returns {
                id
                name
                type {
                  name
                }
                description {
                  childMarkdownRemark {
                    html
                  }
                }
              }
              childrenDocumentationJs {
                id
                name
                description {
                  children {
                    id
                  }
                  childMarkdownRemark {
                    html
                  }
                  childrenMdx {
                    id
                    headings {
                      value
                      depth
                    }
                  }
                }

                params {
                  name
                  type {
                    name
                  }
                  description {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
                examples {
                  highlighted
                  raw
                  description
                }
                type {
                  type
                  name
                  expression
                  result
                }
                returns {
                  id
                  name
                  type {
                    name
                  }
                  description {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  // Convert passed params to JS object
  const params = JSON.parse(files);

  // Retrieve all edges from all Files
  const edges = data.allFile.edges.filter((file) => {
    if (
      `"${file.node.sourceInstanceName}"` === jsrenderer &&
      params.includes(file.node.relativePath)
    ) {
      return file;
    } else {
      return false;
    }
  });

  // Nothing to render return null
  if (edges.length <= 0) {
    return null;
  }

  return params.map((file) => {
    const edge = edges.find((edge) => {
      return edge.node.relativePath === file;
    });

    // if edge is undefined return as this means that the file does not exist

    if (!edge) {
      return null;
    }

    // file name without the extension

    const filename = edge.node.relativePath.split('.')[0];

    return (
      <div style={{ marginTop: '0.5rem' }}>
        <h3 class="heading3" id={`${filename}`}>{filename} </h3>
        <References title={filename}>{filename}</References>

        {edge.node.childrenDocumentationJs.map((doc) => {
          return (
            <>
              {doc.childrenDocumentationJs.length > 1 ? (
                <NodeChildrenRender
                  doc={doc}
                  nodes={[...doc.childrenDocumentationJs]}
                  name={doc.name}
                />
              ) : (
                <NodeRender doc={doc} />
              )}
            </>
          );
        })}
      </div>
    );
  });
};

export default JsRenderer;
