import * as React from 'react';
import styled from '@emotion/styled';
import Link from './link';


const FooterArea = styled('div')`
width: 100%;
position: relative;
background: #000000;
padding:15px;
display: flex;
flex-wrap: nowrap;
flex-direction: row;
align-content: center;
justify-content: center;
align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const FooterContent = styled('div')`
color: #FFFFFF;
padding: 0 36px;
font-weight:700;
font-size:14px;

& a{
  text-decoration:none
}

  @media (max-width: 767px) {
    padding: 10px;
    text-align: center;
    font-size: 14px;
  }
`;

const RightBox = styled('div')`
display: flex; 
justify-content: space-around;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const Footer = () => {
  return (<FooterArea>
    <FooterContent>© mimik technology, Inc. all rights reserved</FooterContent>
    <RightBox>
      <FooterContent>
         <Link to='/misc/02-index' className={'misc'}>
          privacy policy
        </Link> | <Link to='/misc/01-index' className={'misc'}>
          disclaimer
        </Link>
      </FooterContent>
    </RightBox>
  </FooterArea>)
}

export default Footer;