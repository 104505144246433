import React from 'react';
import styled from '@emotion/styled';
import Sidemenu from 'gatsby-plugin-md-menus/sidemenu';

// eslint-disable-next-line no-unused-vars
const ListItem = styled(({ className, active, level, ...props }) => {
  return (
    <li className={className}>
      <a href={props.to} {...props} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    </li>
  );
})`
  list-style: none;

  a {
    color: #5c6975;
    text-decoration: none;
    font-weight: ${({ level }) => (level === 0 ? 700 : 400)};
    padding: 0.45rem 0 0.45rem ${props => 2 + (props.level || 0) * 1}rem;
    display: block;
    position: relative;

    &:hover {
      color: #1ed3c6 !important;
    }

    ${props =>
    props.active &&
    `
      // color: #663399;
      border-color: rgb(230,236,241) !important;
      border-style: solid none solid solid;
      border-width: 1px 0px 1px 1px;
      background-color: #fff;
    `} // external link icon
    svg {
      float: right;
      margin-right: 1rem;
    }
  }
`;



const Sidebar = styled('aside')`
  width: 100%;
  height: calc(100% - 60px);
  overflow: auto;
  position: fixed;
  padding-left: 0px;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: sticky;
  top: 0;
  padding-right: 0; 
  padding-bottom:60px;

   

  @media only screen and (max-width: 1023px) {
    width: 100%; 
    height: 100%;
  }

  @media (min-width: 767px) and (max-width: 1023px) {
    padding-left: 0;
  }

  @media only screen and (max-width: 767px) {
    padding-left: 0px;
    height: auto;
    position:relative;
  }
`;

const Divider = styled(props => (
  <li {...props}>
    <hr />
  </li>
))`
  list-style: none;
  padding: 0.5rem 0;

  hr {
    margin: 0;
    padding: 0;
    border: 0; 
  }
`;

const SidebarLayout = ({setMenu }) => {
  return (
    <Sidemenu setMenu={setMenu} />
  )
};

/*
const SidebarLayout = ({ location, setMenu, ...rest }) => {

  return (
  <StaticQuery
    query={graphql`
      query {
        allMdx {
          edges {
            node {
              fields {
                slug
                title
              }
            }
          }
        }
      }
    `}
    render={({ allMdx }) => {
      return (
        <Sidebar>

          <ul className={'sideBarUL'}>
            <Tree edges={allMdx.edges} setMenu={setMenu} />
            {config.sidebar.links && config.sidebar.links.length > 0 && <Divider />}

          </ul>
        </Sidebar>
      );
    }}
  />
)};
*/

export default SidebarLayout;
