import styled from 'styled-components';

export const Title = styled.p`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  font-weight: ${(props) => (props.isExpanded ? `bold` : `normal`)};
  overflow-wrap: break-word;
`;

export const Doc = styled.div`
  diplay: block;
  flex-flow: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  color: black;
`;

export const DocHeader = styled.h1`
  flex: 0 1 auto;
  width: 100;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
export const Wrapper = styled.div`
  height: 2rem;
  border: 1px solid #80cccf;
  border-radius: 5px 5px ${(props) => (props.isExpanded ? `0 0` : `5px 5px`)};
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  align-content: center;
  padding: 1.1rem;
  &:hover {
    color: blue;
    cursor: pointer;
  }
  font-size: 0.9rem;
  overflow-wrap: break-word;
`;

export const WrapperTitle = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`;

export const CommentWrapper = styled.section`
  background: white;
  height: auto;
  border-top: 0px solid #80cccf;
  border-bottom: 1px solid #80cccf;
  border-left: 1px solid #80cccf;
  border-right: 1px solid #80cccf;
  border-radius: 0 0 5px 5px;
  display: block;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const Example = styled.section`
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #0e2127;
  border-radius: 5px;
  color: white;
`;

export const Params = styled.b`
  color: #548f8f;
  font-weight: bolder;
`;

export const Badge = styled.h4`
  color: #548f8f;
  font-weight: bolder;
`;

export const Group = styled.fieldset`
  color: #548f8f;
  font-weight: bolder;
  padding: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: dotted 1px #80cccf;
  border-radius: 5px;
`;

export const Table = styled.table`
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border-radius: 3px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px #80cccf; /* this draws the table border  */ 
  width: 100%;
  margin-top: 1rem;
`;

export const TableData = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

export const TableHead = styled.th`
   padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #3B454E;
  color: white;
`;
