import * as React from "react";

function ArrowRight() {

  return (
    <svg width="27" height="16" viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 7C0.447715 7 5.46974e-08 7.44772 0 8C-5.46974e-08 8.55228 0.447715 9 1 9L1 7ZM26.6302 8.70711C27.0207 8.31658 27.0207 7.68342 26.6302 7.2929L20.2662 0.928934C19.8757 0.538409 19.2425 0.538409 18.852 0.928934C18.4615 1.31946 18.4615 1.95262 18.852 2.34315L24.5089 8L18.852 13.6569C18.4615 14.0474 18.4615 14.6805 18.852 15.0711C19.2425 15.4616 19.8757 15.4616 20.2662 15.0711L26.6302 8.70711ZM1 9L25.9231 9L25.9231 7L1 7L1 9Z" fill="#A3CD39"/>
</svg>
  );
}


export default ArrowRight;
