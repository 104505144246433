import ReactDOM from 'react-dom';
import React, { useEffect, useState } from 'react';
import { ListItem } from '../styles/Sidebar';

// Refenences 
const References = (props) => {
  const [isBrowser, setIsBrowser] = useState(false);

  // Browser object is available set it to true
  useEffect(() => {
    setIsBrowser(true);
  }, []);

  if (isBrowser) {
    const domNode = document.getElementById('side-references');
    if (domNode) {
      return ReactDOM.createPortal(
        <ListItem to={`#${props.title}`} level={2}>
          {props.children}
        </ListItem>,
        domNode
      );
    }
  }

  return null;
};
export default References;
