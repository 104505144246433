import * as React from "react"
import axios from "axios"


function   useStackOverFlowHook(url) {
    const [data, setData] = React.useState({})

    const updateData = React.useCallback((current_data) => {
        setData(() => {
            return {...current_data}
        })
    }, []);

    React.useEffect(() => {
        axios.get(url)
        .then((response) => {
            updateData(response.data)
        })
        // dependency is missing, however module is working properly
        // Changing it could produce side-effects in related modules 
        // eslint-disable-next-line
    }, []);
    return data;
}

export default useStackOverFlowHook;