import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

// import Link from './link';
import config from '../../config';
import { Sidebar, ListItem } from './styles/Sidebar';

const SidebarLayout = ({ location }) => {
  React.useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });
  const isSticky = () => {
    const header = document.querySelector('.rightSideBarUL');
    const scrollTop = window.scrollY;
    scrollTop >= 250 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
  };
  return (
    <StaticQuery
      query={graphql`
      query {
        allMdx {
          edges {
            node {
              fields {
                slug
              }
              tableOfContents
            }
          }
        }
      }
    `}
      render={({ allMdx }) => {
        let finalNavItems;

        if (allMdx.edges !== undefined && allMdx.edges.length > 0) {
          allMdx.edges.map((item, index) => {
            let innerItems;

            if (item !== undefined && item.node.fields != null) {
              if (
                item.node.fields.slug === location.pathname ||
                config.gatsby.pathPrefix + item.node.fields.slug === location.pathname
              ) {
                if (item.node.tableOfContents.items) {
                  innerItems = item.node.tableOfContents.items.map((innerItem, index) => {
                    const itemId = innerItem.title
                      ? innerItem.title.replace(/\s+/g, '').toLowerCase()
                      : '#';

                    return (
                      <ListItem key={index} to={`#${itemId}`} level={1}>
                        {innerItem.title}
                      </ListItem>
                    );
                  });
                }
              }
            }
            if (innerItems) {
              finalNavItems = innerItems;
            }
            return false;
          });
        }



        if (finalNavItems && finalNavItems.length) {
          return (
            <Sidebar>
              <ul className={'rightSideBarUL'}>
                <ListItem className={'rightSideTitle'}>Content</ListItem>
                {finalNavItems}
                <span id="side-references"></span>
              </ul>
            </Sidebar>
          );
        } else {
          return (
            <Sidebar>
              <ul className={'rightSideBarUL'}>
                <span id="side-references"></span>
              </ul>
            </Sidebar>
          );
        }
      }}
    />
  )
};

export default SidebarLayout;
