import React from 'react';
import NodeRender from './NodeRender';
import { Group } from '../styles';

// Component renders children of a node
const NodeChildrenRender = ({ nodes, name, doc }) => {
  if (nodes.length <= 0) {
    return null;
  }
  return (
    <Group>
      <legend>{name}</legend>
      <NodeRender doc={doc} />
      {nodes.map((doc_node) => {
        return <NodeRender doc={doc_node} />;
      })}
    </Group>
  );
};

export default NodeChildrenRender;
